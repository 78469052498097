import Vue from "vue";
import "@/plugins/axios";
import App from "@/App.vue";
import store from "@/store";
import "@/directives";
import router from "@/router";
import Buefy from "buefy";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "@/assets/scss/app.scss";
import Vuelidate from "vuelidate";
import permisos_check from "./mixins/permisos_check";
import GmapVue from "gmap-vue";
import infiniteScroll from "vue-infinite-scroll";
import VueSignaturePad from "vue-signature-pad";

import googleMapsForm from "@/components/googleMaps/googleMapsForm";
import RichEditor from "@/components/RichEditor/RichEditor.vue";

Vue.component("GoogleMapsForm", googleMapsForm);
Vue.component("RichEditor", RichEditor);

Vue.use(GmapVue, {
  load: { libraries: "places, geocoder" },
  dynamicLoad: true,
});

import "@/_helpers/session_tabs";
import "@/_helpers/filtros";

Vue.component("VSelect", vSelect);

Vue.mixin(permisos_check);

Vue.use(VueSignaturePad);
Vue.use(infiniteScroll);
Vue.use(Vuelidate);
Vue.use(Buefy, {
  defaultIconPack: "fas",
});

Vue.config.productionTip = false;

if (store.state.auth.auth_token) {
  store.dispatch("auth/currentUser").finally(() => {
    store.commit("auth/SET_USER_LOADING", false);
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  });
} else {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

import { axiosInstance } from "@/plugins/axios";
import Vue from "vue";
export default {
  namespaced: true,

  state: () => ({
    roles: {
      isLoading: false,
      isLoaded: false,
      data: [],
      links: {},
      meta: {},
      error: {},
    },
  }),

  mutations: {
    SET_ROLES_DATA(state, roles_data) {
      state.roles.data = roles_data;
    },

    SET_ROLES_LINKS(state, roles_links) {
      state.roles.links = roles_links;
    },

    SET_ROLES_META(state, roles_meta) {
      state.roles.meta = roles_meta;
    },

    UPDATE_ROLE_DATA(state, role_data) {
      let index = state.roles.data.findIndex(
        (role) => role.id === role_data.id,
      );
      Vue.set(state.roles.data, index, role_data);
    },

    ADD_ROLE_DATA(state, role_data) {
      state.roles.data.push(role_data);
    },

    SET_ROLES_ERRORS(state, roles_error) {
      state.roles.error = roles_error;
    },

    SET_ROLES_LOADING(state, roles_loading) {
      state.roles.isLoading = roles_loading;
    },

    SET_ROLES_LOADED(state, roles_loaded) {
      state.roles.isLoaded = roles_loaded;
    },

    CLEAR_ROLES(state) {
      state.roles.isLoading = false;
      state.roles.isLoaded = false;
      state.roles.data = [];
      state.roles.error = {};
    },
  },

  actions: {
    get_roles({ commit }, params = {}) {
      commit("SET_ROLES_LOADING", true);
      return axiosInstance.get("admin/roles", { params: params }).then((e) => {
        commit("SET_ROLES_DATA", e.data.data);
        commit("SET_ROLES_LINKS", e.data.links);
        commit("SET_ROLES_META", e.data.meta);
        commit("SET_ROLES_LOADING", false);
        commit("SET_ROLES_LOADED", true);
        commit("SET_ROLES_ERRORS", {});
      });
    },

    delete_role({ commit, state }, role_id) {
      commit("SET_ROLES_LOADING", true);
      return axiosInstance.delete(`admin/roles/${role_id}`).then(() => {
        const temp_data = state.roles.data.filter(
          (role) => role.id !== role_id,
        );
        commit("SET_ROLES_DATA", temp_data);
        commit("SET_ROLES_LOADING", false);
        commit("SET_ROLES_LOADED", true);
        commit("SET_ROLES_ERRORS", {});
      });
    },
    add_role({ commit }, payload) {
      commit("SET_ROLES_LOADING", true);
      return axiosInstance.post(`admin/roles`, payload).then((e) => {
        commit("ADD_ROLE_DATA", e.data);
        commit("SET_ROLES_LOADING", false);
      });
    },

    update_role({ commit }, payload) {
      commit("SET_ROLES_LOADING", true);
      return axiosInstance
        .patch(`admin/roles/${payload.id}`, payload)
        .then((e) => {
          commit("UPDATE_ROLE_DATA", e.data);
          commit("SET_ROLES_LOADING", false);
        });
    },

    ROLES_GET_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_ROLES_ERRORS", error);
      commit("SET_ROLES_LOADING", false);
      commit("SET_ROLES_LOADED", false);
    },
  },

  getters: {
    rolesData: (state) => state.roles,
  },
};

<template>
  <div class="is-relative">
    <b-field class="field py-1" label="Lugar de registro">
      <b-input
        v-model="juridico.registro_lugar"
        @change.native="updateJuridico"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Tomo">
      <b-input
        v-model="juridico.registro_tomo"
        @change.native="updateJuridico"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Folio">
      <b-input
        v-model="juridico.registro_folio"
        @change.native="updateJuridico"
      ></b-input>
    </b-field>

    <!-- Fecha de juridico -->
    <b-field class="field py-1" label="Fecha del registro">
      <b-datepicker
        v-model="juridico.registro_fecha"
        placeholder="Fecha"
        :date-formatter="dateFormatter"
        locale="es-MX"
        :show-week-number="true"
        :mobile-native="false"
        :trap-focus="true"
        @input="updateJuridico"
      ></b-datepicker>
    </b-field>

    <!-- Estado -->
    <b-field class="field py-1" label="Estado">
      <b-select
        v-model="juridico.state_id"
        expanded
        placeholder="Seleccione un Estado"
        @input="estadosSelectCallback"
      >
        <option
          v-for="estado in estados"
          :key="estado.nombre + estado.id"
          :value="estado.id"
        >
          {{ estado.nombre }}
        </option>
      </b-select>
    </b-field>
    <!-- Ciudad -->
    <b-field class="field py-1" label="Ciudad">
      <b-select
        v-model="juridico.ciudad_id"
        expanded
        :loading="LoadingCities"
        placeholder="Seleccione una Ciudad"
        @input="updateJuridico"
      >
        <option
          v-for="ciudad in ciudades"
          :key="ciudad.nombre + ciudad.id"
          :value="ciudad.id"
        >
          {{ ciudad.nombre }}
        </option>
      </b-select>
    </b-field>

    <b-field class="field py-1" label="Tipo de sociedad">
      <b-input
        v-model="juridico.tipo_sociedad"
        @change.native="updateJuridico"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Dirección fiscal">
      <b-input
        v-model="juridico.registro_direccion"
        type="textarea"
        maxlength="1000"
        @change.native="updateJuridico"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Nombre del responsable">
      <b-input
        v-model="juridico.responsable_nombre"
        @change.native="updateJuridico"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Sexo">
      <b-select
        v-model="juridico.responsable_sexo"
        expanded
        placeholder="Seleccione un Sexo"
        @input="updateJuridico"
      >
        <option :value="'Hombre'">Hombre</option>
        <option :value="'Mujer'">Mujer</option>
      </b-select>
    </b-field>

    <b-field class="field py-1" label="Cargo del responsable">
      <b-input
        v-model="juridico.responsable_cargo"
        @change.native="updateJuridico"
      ></b-input>
    </b-field>

    <b-field label="Documento del responsable">
      <b-select
        v-model="juridico.responsable_documento_tipo"
        @change.native="updateJuridico"
      >
        <option value="V">V</option>
        <option value="E">E</option>
      </b-select>
      <b-input
        v-model="juridico.responsable_documento_numero"
        placeholder="Numero de documento"
        @change.native="updateJuridico"
      ></b-input>
    </b-field>

    <!-- Fecha de juridico -->
    <b-field class="field py-1" label="Fecha de nacimiento del responsable">
      <b-datepicker
        v-model="juridico.responsable_fecha_nacimiento"
        placeholder="Fecha"
        :date-formatter="dateFormatter"
        locale="es-MX"
        :show-week-number="true"
        :mobile-native="false"
        :trap-focus="true"
        @input="updateJuridico"
      ></b-datepicker>
    </b-field>

    <b-loading :active="isLoading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
import http_errors from "@/mixins/http_errors";
import dayjs from "dayjs";
export default {
  name: "JuridicoJuridicoForm",
  mixins: [http_errors],
  props: {
    parteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      estadosLoading: false,
      LoadingCities: false,
      estados: [],
      ciudades: [],
      juridico: {
        id: null,
        registro_lugar: null,
        registro_tomo: null,
        registro_folio: null,
        registro_fecha: null,
        ciudad_id: null,
        state_id: null,
        tipo_sociedad: null,
        registro_direccion: null,
        responsable_nombre: null,
        responsable_cargo: null,
        responsable_documento_tipo: null,
        responsable_documento_numero: null,
        responsable_fecha_nacimiento: null,
      },
    };
  },

  mounted() {
    this.fetchRegistro();
    this.fetchEstados();
  },

  methods: {
    dateFormatter(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },

    fetchRegistro() {
      this.isLoading = true;
      this.$axios
        .get("juridicos", {
          params: {
            parte_id: this.parteId,
            token: this.$route.query.token,
          },
        })
        .then((e) => {
          this.$set(this, "juridico", {
            ...this.juridico,
            ...e.data.data,
            registro_fecha: e.data.data.registro_fecha
              ? new Date(e.data.data.registro_fecha + " 12:00:00")
              : null,
            responsable_fecha_nacimiento: e.data.data
              .responsable_fecha_nacimiento
              ? new Date(e.data.data.responsable_fecha_nacimiento + " 12:00:00")
              : null,
          });
          if (this.juridico.state_id) {
            this.fetchCiudades(this.juridico.state_id);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.http_parse_error(error);
        });
    },

    fetchEstados() {
      this.estadosLoading = true;
      this.$axios
        .get("estados")
        .then((e) => {
          this.estados = e.data.data;
          this.estadosLoading = false;
        })
        .catch((error) => {
          this.estadosLoading = false;
          this.http_parse_error(error);
        });
    },

    estadosSelectCallback(estado_id) {
      this.ciudad_id = null;
      this.fetchCiudades(estado_id);
    },

    fetchCiudades(estado_id) {
      if (!estado_id) {
        return;
      }
      this.LoadingCities = true;
      this.axios
        .get(`estados/${estado_id}/ciudades`)
        .then((e) => {
          this.ciudades = e.data.data;
          this.LoadingCities = false;
        })
        .catch((error) => {
          this.LoadingCities = false;
          this.http_parse_error(error);
        });
    },

    updateJuridico() {
      let payload = { ...this.juridico };
      if (this.juridico.registro_fecha) {
        payload.registro_fecha = dayjs(this.juridico.registro_fecha).format(
          "YYYY-MM-DD",
        );
      }

      if (this.juridico.responsable_fecha_nacimiento) {
        payload.responsable_fecha_nacimiento = dayjs(
          this.juridico.responsable_fecha_nacimiento,
        ).format("YYYY-MM-DD");
      }

      payload.token = this.$route.query.token;

      this.$axios.put(`juridicos/${payload.id}`, payload).catch((error) => {
        this.http_parse_error(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

import store from "@/store";

export default {
  inserted(el, binding) {
    const { value } = binding;
    const user_permisos = store.getters && store.getters["auth/userPermisos"];

    if (value && value instanceof Array && value.length > 0) {
      const requiredPermissions = value;
      const hasPermission = user_permisos.some((permission) => {
        return requiredPermissions.includes(permission);
      });

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(
        `Permissions are required! Example: v-permission="['manage user','manage permission']"`,
      );
    }
  },
};

import { axiosInstance } from "@/plugins/axios";
import Vue from "vue";
export default {
  namespaced: true,

  state: () => ({
    models_url: "",
    models: {
      isLoading: false,
      isLoaded: false,
      data: [],
      links: {},
      meta: {},
      error: {},
    },
  }),

  mutations: {
    SET_MODELS_URL(state, models_url) {
      state.models_url = models_url;
    },

    SET_MODELS_DATA(state, models_data) {
      state.models.data = models_data;
    },

    SET_MODELS_LINKS(state, models_links) {
      state.models.links = models_links;
    },

    SET_MODELS_META(state, models_meta) {
      state.models.meta = models_meta;
    },

    UPDATE_MODEL_DATA(state, model_data) {
      let index = state.models.data.findIndex(
        (model) => model.id === model_data.id,
      );
      Vue.set(state.models.data, index, model_data);
    },

    ADD_MODEL_DATA(state, model_data) {
      state.models.data.push(model_data);
    },

    SET_MODELS_ERRORS(state, models_error) {
      state.models.error = models_error;
    },

    SET_MODELS_LOADING(state, models_loading) {
      state.models.isLoading = models_loading;
    },

    SET_MODELS_LOADED(state, models_loaded) {
      state.models.isLoaded = models_loaded;
    },

    CLEAR_MODELS(state) {
      state.models.isLoading = false;
      state.models.isLoaded = false;
      state.models.data = [];
      state.models.links = [];
      state.models.meta = [];
      state.models.error = {};
    },
  },

  actions: {
    get_models({ commit, state }, params = {}) {
      commit("SET_MODELS_LOADING", true);
      return axiosInstance
        .get(`admin/${state.models_url}`, { params: params })
        .then((e) => {
          commit("SET_MODELS_DATA", e.data.data);
          commit("SET_MODELS_LINKS", e.data.links);
          commit("SET_MODELS_META", e.data.meta);
          commit("SET_MODELS_LOADING", false);
          commit("SET_MODELS_LOADED", true);
          commit("SET_MODELS_ERRORS", {});
        });
    },

    delete_models({ commit, state }, model_id) {
      commit("SET_MODELS_LOADING", true);
      return axiosInstance
        .delete(`admin/${state.models_url}/${model_id}`)
        .then(() => {
          const temp_data = state.models.data.filter(
            (model) => model.id !== model_id,
          );
          commit("SET_MODELS_DATA", temp_data);
          commit("SET_MODELS_LOADING", false);
          commit("SET_MODELS_LOADED", true);
          commit("SET_MODELS_ERRORS", {});
        });
    },
    add_models({ commit, state }, payload) {
      commit("SET_MODELS_LOADING", true);
      return axiosInstance
        .post(`admin/${state.models_url}`, payload)
        .then((e) => {
          commit("ADD_MODEL_DATA", e.data);
          commit("SET_MODELS_LOADING", false);
          return e;
        });
    },

    update_models({ commit, state }, payload) {
      commit("SET_MODELS_LOADING", true);
      return axiosInstance
        .patch(`admin/${state.models_url}/${payload.id}`, payload)
        .then((e) => {
          commit("UPDATE_MODEL_DATA", e.data);
          commit("SET_MODELS_LOADING", false);
          return e;
        });
    },

    MODELS_GET_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_MODELS_ERRORS", error);
      commit("SET_MODELS_LOADING", false);
      commit("SET_MODELS_LOADED", false);
    },
  },

  getters: {
    modelsData: (state) => state.models,
  },
};

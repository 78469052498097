import { axiosInstance } from "@/plugins/axios";
import Vue from "vue";
export default {
  namespaced: true,

  state: () => ({
    publicaciones: {
      isLoading: false,
      isLoaded: false,
      data: [],
      error: {},
      meta: {},
    },

    perfiles: {
      isLoading: false,
      isLoaded: false,
      data: [],
      error: {},
      meta: {},
    },

    contratos: {
      isLoading: false,
      isLoaded: false,
      data: [],
      error: {},
      meta: {},
    },

    favoritosPublicaciones: {
      isLoading: false,
      isLoaded: false,
      data: [],
      error: {},
      meta: {},
    },

    favoritosPerfiles: {
      isLoading: false,
      isLoaded: false,
      data: [],
      error: {},
      meta: {},
    },

    model: {
      isLoading: false,
      isLoaded: false,
      data: {},
      error: {},
    },
  }),

  mutations: {
    // Model
    SET_MODEL_DATA(state, model_data) {
      Vue.set(state.model, "data", model_data);
    },

    SET_MODEL_ERRORS(state, model_error) {
      state.model.error = model_error;
    },

    SET_MODEL_LOADING(state, model_loading) {
      state.model.isLoading = model_loading;
    },

    SET_MODEL_LOADED(state, model_loaded) {
      state.model.isLoaded = model_loaded;
    },

    CLEAR_MODEL(state) {
      state.model.isLoading = false;
      state.model.isLoaded = false;
      state.model.data = [];
      state.model.error = {};
    },

    // Publicaciones
    SET_PUBLICACIONES_DATA(state, publicaciones_data) {
      Vue.set(state.publicaciones, "data", publicaciones_data);
    },

    SET_PUBLICACIONES_META(state, publicaciones_meta) {
      state.publicaciones.meta = publicaciones_meta;
    },

    SET_PUBLICACIONES_ERRORS(state, publicaciones_error) {
      state.publicaciones.error = publicaciones_error;
    },

    SET_PUBLICACIONES_LOADING(state, publicaciones_loading) {
      state.publicaciones.isLoading = publicaciones_loading;
    },

    SET_PUBLICACIONES_LOADED(state, publicaciones_loaded) {
      state.publicaciones.isLoaded = publicaciones_loaded;
    },

    CLEAR_PUBLICACIONES(state) {
      state.publicaciones.isLoading = false;
      state.publicaciones.isLoaded = false;
      state.publicaciones.data = [];
      state.publicaciones.error = {};
    },

    // Favoritos Perfiles
    SET_FAVORITOS_PERFILES_DATA(state, favoritosPerfiles_data) {
      Vue.set(state.favoritosPerfiles, "data", favoritosPerfiles_data);
    },

    SET_FAVORITOS_PERFILES_META(state, favoritosPerfiles_meta) {
      state.favoritosPerfiles.meta = favoritosPerfiles_meta;
    },

    SET_FAVORITOS_PERFILES_ERRORS(state, favoritosPerfiles_error) {
      state.favoritosPerfiles.error = favoritosPerfiles_error;
    },

    SET_FAVORITOS_PERFILES_LOADING(state, favoritosPerfiles_loading) {
      state.favoritosPerfiles.isLoading = favoritosPerfiles_loading;
    },

    SET_FAVORITOS_PERFILES_LOADED(state, favoritosPerfiles_loaded) {
      state.favoritosPerfiles.isLoaded = favoritosPerfiles_loaded;
    },

    CLEAR_FAVORITOS_PERFILES(state) {
      state.favoritosPerfiles.isLoading = false;
      state.favoritosPerfiles.isLoaded = false;
      state.favoritosPerfiles.data = [];
      state.favoritosPerfiles.error = {};
    },

    // Favoritos Publicaciones
    SET_FAVORITOS_PUBLICACIONES_DATA(state, favoritosPublicaciones_data) {
      Vue.set(
        state.favoritosPublicaciones,
        "data",
        favoritosPublicaciones_data,
      );
    },

    SET_FAVORITOS_PUBLICACIONES_META(state, favoritosPublicaciones_meta) {
      state.favoritosPublicaciones.meta = favoritosPublicaciones_meta;
    },

    SET_FAVORITOS_PUBLICACIONES_ERRORS(state, favoritosPublicaciones_error) {
      state.favoritosPublicaciones.error = favoritosPublicaciones_error;
    },

    SET_FAVORITOS_PUBLICACIONES_LOADING(state, favoritosPublicaciones_loading) {
      state.favoritosPublicaciones.isLoading = favoritosPublicaciones_loading;
    },

    SET_FAVORITOS_PUBLICACIONES_LOADED(state, favoritosPublicaciones_loaded) {
      state.favoritosPublicaciones.isLoaded = favoritosPublicaciones_loaded;
    },

    CLEAR_FAVORITOS_PUBLICACIONES(state) {
      state.favoritosPublicaciones.isLoading = false;
      state.favoritosPublicaciones.isLoaded = false;
      state.favoritosPublicaciones.data = [];
      state.favoritosPublicaciones.error = {};
    },

    // Perfiles
    SET_PERFILES_DATA(state, perfiles_data) {
      Vue.set(state.perfiles, "data", perfiles_data);
    },

    SET_PERFILES_META(state, perfiles_meta) {
      state.perfiles.meta = perfiles_meta;
    },

    SET_PERFILES_ERRORS(state, perfiles_error) {
      state.perfiles.error = perfiles_error;
    },

    SET_PERFILES_LOADING(state, perfiles_loading) {
      state.perfiles.isLoading = perfiles_loading;
    },

    SET_PERFILES_LOADED(state, perfiles_loaded) {
      state.perfiles.isLoaded = perfiles_loaded;
    },

    CLEAR_PERFILES(state) {
      state.perfiles.isLoading = false;
      state.perfiles.isLoaded = false;
      state.perfiles.data = [];
      state.perfiles.error = {};
    },

    // Contratos
    SET_CONTRATOS_DATA(state, contratos_data) {
      Vue.set(state.contratos, "data", contratos_data);
    },

    SET_CONTRATOS_META(state, contratos_meta) {
      state.contratos.meta = contratos_meta;
    },

    SET_CONTRATOS_ERRORS(state, contratos_error) {
      state.contratos.error = contratos_error;
    },

    SET_CONTRATOS_LOADING(state, contratos_loading) {
      state.contratos.isLoading = contratos_loading;
    },

    SET_CONTRATOS_LOADED(state, contratos_loaded) {
      state.contratos.isLoaded = contratos_loaded;
    },

    CLEAR_CONTRATOS(state) {
      state.contratos.isLoading = false;
      state.contratos.isLoaded = false;
      state.contratos.data = [];
      state.contratos.error = {};
    },
  },

  actions: {
    // Modelo
    getPublicacionById({ commit }, options = {}) {
      commit("SET_MODEL_LOADING", true);
      return axiosInstance
        .get(`mis-publicaciones/${options.id}`, { params: options })
        .then((e) => {
          commit("SET_MODEL_DATA", e.data.data);
          commit("SET_MODEL_LOADED", true);
          commit("SET_MODEL_LOADING", false);
          commit("SET_MODEL_ERRORS", {});
          return e;
        });
    },

    getPerfilById({ commit }, options = {}) {
      commit("SET_MODEL_LOADING", true);
      return axiosInstance
        .get(`mis-perfiles/${options.id}`, { params: options })
        .then((e) => {
          commit("SET_MODEL_DATA", e.data.data);
          commit("SET_MODEL_LOADED", true);
          commit("SET_MODEL_LOADING", false);
          commit("SET_MODEL_ERRORS", {});
          return e;
        });
    },

    MODEL_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_MODEL_ERRORS", error);
      commit("SET_MODEL_LOADING", false);
      commit("SET_MODEL_LOADED", false);
    },

    delete_model({ commit, dispatch }, payload) {
      commit("SET_PUBLICACIONES_LOADING", true);
      return axiosInstance
        .delete(`${payload.model}/${payload.id}`)
        .then((e) => {
          dispatch("getPublicaciones");
          return e;
        });
    },

    change_estatus_model({ commit, dispatch }, payload) {
      commit(`SET_${payload.endpoint.toUpperCase()}_LOADING`, true);
      return axiosInstance
        .put(`${payload.endpoint}/${payload.id}`)
        .then((e) => {
          dispatch(payload.fetch);
          return e;
        });
    },

    // Publicaciones
    getPublicaciones({ commit }, options = {}) {
      commit("SET_PUBLICACIONES_LOADING", true);
      return axiosInstance
        .get("mis-publicaciones", { params: options })
        .then((e) => {
          commit("SET_PUBLICACIONES_DATA", e.data.data);
          commit("SET_PUBLICACIONES_META", e.data.meta);
          commit("SET_PUBLICACIONES_LOADED", true);
          commit("SET_PUBLICACIONES_LOADING", false);
          commit("SET_PUBLICACIONES_ERRORS", {});
          return e;
        });
    },

    PUBLICACIONES_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_PUBLICACIONES_ERRORS", error);
      commit("SET_PUBLICACIONES_LOADING", false);
      commit("SET_PUBLICACIONES_LOADED", false);
    },

    // Favoritos Perfiles
    getFavoritosPerfiles({ commit }, options = {}) {
      commit("SET_FAVORITOS_PERFILES_LOADING", true);
      return axiosInstance
        .get("mis-perfiles/favoritos", { params: options })
        .then((e) => {
          commit("SET_FAVORITOS_PERFILES_DATA", e.data.data);
          commit("SET_FAVORITOS_PERFILES_META", e.data.meta);
          commit("SET_FAVORITOS_PERFILES_LOADED", true);
          commit("SET_FAVORITOS_PERFILES_LOADING", false);
          commit("SET_FAVORITOS_PERFILES_ERRORS", {});
          return e;
        });
    },

    removeFavoritosPerfiles({ commit, dispatch }, options = {}) {
      commit("SET_FAVORITOS_PERFILES_LOADING", true);
      return axiosInstance
        .post(`mis-perfiles/remove-favorite`, {
          perfil_id: options.perfilId,
        })
        .then((e) => {
          dispatch("getFavoritosPerfiles");
          return e;
        });
    },
    FAVORITOS_PERFILES_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_FAVORITOS_PERFILES_ERRORS", error);
      commit("SET_FAVORITOS_PERFILES_LOADING", false);
      commit("SET_FAVORITOS_PERFILES_LOADED", false);
    },

    // Favoritos Publicaciones
    getFavoritosPublicaciones({ commit }, options = {}) {
      commit("SET_FAVORITOS_PUBLICACIONES_LOADING", true);
      return axiosInstance
        .get("mis-publicaciones/favoritos", { params: options })
        .then((e) => {
          commit("SET_FAVORITOS_PUBLICACIONES_DATA", e.data.data);
          commit("SET_FAVORITOS_PUBLICACIONES_META", e.data.meta);
          commit("SET_FAVORITOS_PUBLICACIONES_LOADED", true);
          commit("SET_FAVORITOS_PUBLICACIONES_LOADING", false);
          commit("SET_FAVORITOS_PUBLICACIONES_ERRORS", {});
          return e;
        });
    },

    removeFavoritosPublicaciones({ commit, dispatch }, options = {}) {
      commit("SET_FAVORITOS_PUBLICACIONES_LOADING", true);
      return axiosInstance
        .post(`mis-publicaciones/remove-favorite`, {
          publication_id: options.publicacionId,
        })
        .then((e) => {
          dispatch("getFavoritosPublicaciones");
          return e;
        });
    },
    FAVORITOS_PUBLICACIONES_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_FAVORITOS_PUBLICACIONES_ERRORS", error);
      commit("SET_FAVORITOS_PUBLICACIONES_LOADING", false);
      commit("SET_FAVORITOS_PUBLICACIONES_LOADED", false);
    },

    // Perfiles
    getPerfiles({ commit }, options = {}) {
      commit("SET_PERFILES_LOADING", true);
      return axiosInstance
        .get("mis-perfiles", { params: options })
        .then((e) => {
          commit("SET_PERFILES_DATA", e.data.data);
          commit("SET_PERFILES_META", e.data.meta);
          commit("SET_PERFILES_LOADED", true);
          commit("SET_PERFILES_LOADING", false);
          commit("SET_PERFILES_ERRORS", {});
          return e;
        });
    },

    PERFILES_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_PERFILES_ERRORS", error);
      commit("SET_PERFILES_LOADING", false);
      commit("SET_PERFILES_LOADED", false);
    },

    // Contratos
    getContratos({ commit }, options = {}) {
      commit("SET_CONTRATOS_LOADING", true);
      return axiosInstance
        .get("mis-contratos", { params: options })
        .then((e) => {
          commit("SET_CONTRATOS_DATA", e.data.data);
          commit("SET_CONTRATOS_META", e.data.meta);
          commit("SET_CONTRATOS_LOADED", true);
          commit("SET_CONTRATOS_LOADING", false);
          commit("SET_CONTRATOS_ERRORS", {});
          return e;
        });
    },

    CONTRATOS_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_CONTRATOS_ERRORS", error);
      commit("SET_CONTRATOS_LOADING", false);
      commit("SET_CONTRATOS_LOADED", false);
    },
  },

  getters: {
    publicacionesData: (state) => state.publicaciones,
    publicacionById: (state) => (id) => {
      state.publicaciones.find((publicacion) => publicacion.id == id);
    },

    favoritosPerfilesData: (state) => state.favoritosPerfiles,
    favoritosPerfilById: (state) => (id) => {
      state.favoritosPerfiles.find((favorito) => favorito.id == id);
    },

    favoritosPublicacionesData: (state) => state.favoritosPublicaciones,
    favoritosPublicacionById: (state) => (id) => {
      state.favoritosPublicaciones.find((favorito) => favorito.id == id);
    },

    perfilesData: (state) => state.perfiles,
    perfilById: (state) => (id) => {
      state.perfiles.find((perfil) => perfil.id == id);
    },

    contratosData: (state) => state.contratos,
    contratoById: (state) => (id) => {
      state.contratos.find((contrato) => contrato.id == id);
    },
  },
};

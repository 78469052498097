export const pagos_clases = {
  Aceptada: "has-text-success",
  Pendiente: "has-text-warning",
  Retenida: "has-text-warning",
  Iniciada: "has-text-warning",
  Rechazada: "has-text-danger",
  Fallida: "has-text-danger",
  Reversada: "has-text-danger",
  Expirada: "has-text-danger",
  Abandonada: "has-text-danger",
  Cancelada: "has-text-danger",
  Antifraude: "has-text-danger",
};

export const status_suscripciones_clases = {
  active: "has-text-success",
  pending: "has-text-warning",
  canceled: "has-text-danger",
  inactive: "has-text-grey-light",
};

export const status_suscripciones_map = {
  active: "Activa",
  pending: "Pendiente",
  canceled: "Cancelada",
  inactive: "Inactive",
};

export const tipo_propiedades = [
  {
    nombre: "Apartamentos",
  },
  {
    nombre: "Casas",
  },
  {
    nombre: "Fincas",
  },
  {
    nombre: "Lotes",
  },
  {
    nombre: "Oficinas",
  },
  {
    nombre: "Edificios",
  },
  {
    nombre: "Parqueadero",
  },
  {
    nombre: "Bodegas",
  },
  {
    nombre: "Locales",
  },
  {
    nombre: "Vehículos",
  },
  {
    nombre: "Motos",
  },
  {
    nombre: "Bien inmueble",
  },
  {
    nombre: "Otros",
  },
];

export const pujas_minimas = [
  {
    valor: [0, 30000000],
    label: "0 - 30M",
  },
  {
    valor: [30000000, 50000000],
    label: "30M - 50M",
  },
  {
    valor: [50000000, 100000000],
    label: "50M - 100M",
  },
  {
    valor: [100000000, 200000000],
    label: "100M - 200M",
  },
  {
    valor: [200000000, 300000000],
    label: "200M - 300M",
  },
  {
    valor: [300000000, 100000000000],
    label: "300M o Más",
  },
];

export const intervalos_planes = [
  {
    label: "Diario",
    value: "day",
  },
  {
    label: "Semanal",
    value: "week",
  },
  {
    label: "Mensual",
    value: "month",
  },
  {
    label: "Anual",
    value: "year",
  },
];

export const departamentos_planes = [
  {
    id: "05",
    nombre: "ANTIOQUIA",
  },
  {
    id: "25",
    nombre: "CUNDINAMARCA",
  },
  {
    id: "76",
    nombre: "VALLE DEL CAUCA",
  },
  {
    id: "91",
    nombre: "AMAZONAS",
  },
  {
    id: "81",
    nombre: "ARAUCA",
  },
  {
    id: "08",
    nombre: "ATLÁNTICO",
  },
  {
    id: "13",
    nombre: "BOLÍVAR",
  },
  {
    id: "15",
    nombre: "BOYACÁ",
  },
  {
    id: "17",
    nombre: "CALDAS",
  },
  {
    id: "18",
    nombre: "CAQUETÁ",
  },
  {
    id: "85",
    nombre: "CASANARE",
  },
  {
    id: "19",
    nombre: "CAUCA",
  },
  {
    id: "20",
    nombre: "CESAR",
  },
  {
    id: "27",
    nombre: "CHOCÓ",
  },
  {
    id: "23",
    nombre: "CÓRDOBA",
  },
  {
    id: "94",
    nombre: "GUAINÍA",
  },
  {
    id: "95",
    nombre: "GUAVIARE",
  },
  {
    id: "41",
    nombre: "HUILA",
  },
  {
    id: "44",
    nombre: "LA GUAJIRA",
  },
  {
    id: "47",
    nombre: "MAGDALENA",
  },
  {
    id: "50",
    nombre: "META",
  },
  {
    id: "52",
    nombre: "NARIÑO",
  },
  {
    id: "54",
    nombre: "NORTE DE SANTANDER",
  },
  {
    id: "86",
    nombre: "PUTUMAYO",
  },
  {
    id: "63",
    nombre: "QUINDÍO",
  },
  {
    id: "66",
    nombre: "RISARALDA",
  },
  {
    id: "88",
    nombre: "SAN ANDRÉS Y PROVIDENCIA",
  },
  {
    id: "68",
    nombre: "SANTANDER",
  },
  {
    id: "70",
    nombre: "SUCRE",
  },
  {
    id: "73",
    nombre: "TOLIMA",
  },
];

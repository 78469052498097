<template>
  <div class="is-relative">
    <!-- Fecha de inicio -->
    <b-field class="field py-1" label="Fecha de inicio del contrato">
      <b-datepicker
        v-model="contrato.fecha"
        placeholder="Fecha"
        :date-formatter="dateFormatter"
        locale="es-MX"
        :show-week-number="true"
        :mobile-native="false"
        :trap-focus="true"
        @input="updateContrato"
      ></b-datepicker>
    </b-field>

    <b-field class="field py-1" label="Costo">
      <b-input
        v-model.number="contrato.costo"
        type="number"
        :min="0"
        @change.native="updateContrato"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Meses de duración del contrato">
      <b-input
        v-model.number="contrato.meses"
        type="number"
        :min="0"
        @change.native="updateContrato"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Periodo de pago">
      <b-select
        v-model="contrato.periodo"
        expanded
        placeholder="Seleccione un periodo de pago"
        @input="updateContrato"
      >
        <option :value="'Semanal'">Semanal</option>
        <option :value="'Mensual'">Mensual</option>
        <option :value="'Bimensual'">Bimensual</option>
        <option :value="'Anual'">Anual</option>
      </b-select>
    </b-field>

    <b-field class="field py-1">
      <template #label>
        Numero de depositos
        <b-tooltip
          type="is-dark"
          label="Cantidad de periodos de deposito dados. Ejemplo: 2 canons de un periodo mensual serian dos (2) meses de deposito."
        >
          <b-icon size="is-small" icon="question-circle"></b-icon>
        </b-tooltip>
      </template>
      <b-select
        v-model="contrato.meses_deposito"
        expanded
        placeholder="Seleccione el numero de depósitos"
        @input="updateContrato"
      >
        <option v-for="dia in 31" :key="'depositos' + dia" :value="dia">
          {{ dia }}
        </option>
      </b-select>
    </b-field>

    <b-field class="field py-1">
      <template #label>
        Meses de anticipación
        <b-tooltip
          type="is-dark"
          label="Meses de anticipación para notificar la renovación del contrato."
        >
          <b-icon size="is-small" icon="question-circle"></b-icon>
        </b-tooltip>
      </template>
      <b-select
        v-model="contrato.meses_anticipacion"
        expanded
        placeholder="Seleccione los meses de anticipación"
        @input="updateContrato"
      >
        <option v-for="dia in 12" :key="'depositos' + dia" :value="dia">
          {{ dia }}
        </option>
      </b-select>
    </b-field>

    <b-field class="field py-1">
      <template #label>
        Fecha de pago (dia)
        <b-tooltip
          type="is-dark"
          label="Dia en que se haran los pagos de forma periodica. ejemplo: 12 -> los dias doce (12) de cada mes"
        >
          <b-icon size="is-small" icon="question-circle"></b-icon>
        </b-tooltip>
      </template>
      <b-select
        v-model="contrato.fecha_pago"
        expanded
        placeholder="Seleccione la fecha de pago (dia)"
        @input="updateContrato"
      >
        <option v-for="dia in 31" :key="'depositos' + dia" :value="dia">
          {{ dia }}
        </option>
      </b-select>
    </b-field>

    <!-- moneda -->
    <b-field class="field py-1" label="Moneda de pago">
      <b-select
        v-model="contrato.moneda"
        expanded
        placeholder="Seleccione una moneda de pago"
        @input="updateContrato"
      >
        <option :value="'Bolivares'">Bolivares</option>
        <option :value="'Dólares'">Dólares</option>
      </b-select>
    </b-field>

    <b-field class="field py-1" label="Pago inicial">
      <b-input
        v-model="contrato.pago_inicial"
        type="number"
        :min="0"
        @change.native="updateContrato"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Cuenta de pago">
      <b-input
        v-model="contrato.numero_cuenta"
        type="text"
        @change.native="updateContrato"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Penalización por incumplimiento">
      <b-input
        v-model="contrato.penalizacion_incumplimiento"
        type="textarea"
        rows="5"
        @change.native="updateContrato"
      ></b-input>
    </b-field>

    <!-- condicion de contrato -->
    <b-field class="field py-1" label="Condición de contrato">
      <b-input
        v-model="contrato.condicion_contrato"
        type="text"
        @change.native="updateContrato"
      ></b-input>
    </b-field>

    <b-field class="field py-1" label="Informacion adicional">
      <b-input
        v-model="contrato.informacion_adicional"
        type="textarea"
        rows="5"
        @change.native="updateContrato"
      ></b-input>
    </b-field>

    <b-loading :active="isLoading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
import http_errors from "@/mixins/http_errors";
import dayjs from "dayjs";
export default {
  name: "ContratoForm",
  mixins: [http_errors],
  props: {
    contratoId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      estadosLoading: false,
      LoadingCities: false,
      estados: [],
      ciudades: [],
      contrato: {
        id: this.contratoId,
        fecha: null,
        meses: null,
        meses_deposito: null,
        periodo: null,
        fecha_pago: null,
        condicion_contrato: null,
        meses_anticipacion: null,
        pago_inicial: null,
        moneda: null,
        numero_cuenta: null,
        penalizacion_incumplimiento: null,
        informacion_adicional: null,
      },
    };
  },

  mounted() {
    this.fetchContrato();
  },

  methods: {
    dateFormatter(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },

    fetchContrato() {
      this.isLoading = true;
      this.$axios
        .get(`contratos/${this.contratoId}`)
        .then((e) => {
          if (
            e.data.data.estatus_contrato != "Rechazado" &&
            e.data.data.estatus_contrato != "En creación"
          ) {
            this.isLoading = false;
            this.$buefy.dialog.alert({
              message:
                'No se puede editar un contrato a menos que su estatus sea "Rechazado" o "En creación"',
              confirmText: "Cerrar",
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fas",
              ariaRole: "alertdialog",
              ariaModal: true,
              onConfirm: () => {
                this.$router.replace({ name: "Home" });
              },
            });
            return;
          }

          this.$set(this, "contrato", {
            ...this.contrato,
            ...e.data.data,
            costo: e.data.data.costo
              ? e.data.data.costo
              : e.data.data.publicacion.info.costo,
            fecha: e.data.data.fecha
              ? new Date(e.data.data.fecha + " 12:00:00")
              : null,
          });
          this.$emit("contrato", this.contrato);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.http_parse_error(error);
          this.$router.replace({ name: "ContratosUser" });
        });
    },

    updateContrato() {
      let payload = { ...this.contrato };
      if (this.contrato.fecha) {
        payload.fecha = dayjs(this.contrato.fecha).format("YYYY-MM-DD");
      }
      this.$axios.put(`contratos/${payload.id}`, payload).catch((error) => {
        this.http_parse_error(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

import { axiosInstance } from "@/plugins/axios";
import Vue from "vue";
export default {
  namespaced: true,

  state: () => ({
    usuarios: {
      isLoading: false,
      isLoaded: false,
      data: [],
      links: {},
      meta: {},
      error: {},
    },
  }),

  mutations: {
    SET_USUARIOS_DATA(state, usuarios_data) {
      state.usuarios.data = usuarios_data;
    },

    SET_USUARIOS_LINKS(state, usuarios_links) {
      state.usuarios.links = usuarios_links;
    },

    SET_USUARIOS_META(state, usuarios_meta) {
      state.usuarios.meta = usuarios_meta;
    },

    ADD_USUARIO_DATA(state, usuario_data) {
      state.usuarios.data.push(usuario_data);
    },

    UPDATE_USUARIO_DATA(state, user_data) {
      let index = state.usuarios.data.findIndex(
        (usuario) => usuario.id === user_data.id,
      );
      Vue.set(state.usuarios.data, index, user_data);
    },

    SET_USUARIOS_ERRORS(state, usuarios_error) {
      state.usuarios.error = usuarios_error;
    },

    SET_USUARIOS_LOADING(state, usuarios_loading) {
      state.usuarios.isLoading = usuarios_loading;
    },

    SET_USUARIOS_LOADED(state, usuarios_loaded) {
      state.usuarios.isLoaded = usuarios_loaded;
    },

    CLEAR_USUARIOS(state) {
      state.usuarios.isLoading = false;
      state.usuarios.isLoaded = false;
      state.usuarios.data = [];
      state.usuarios.error = {};
    },
  },

  actions: {
    get_usuarios({ commit }, params = {}) {
      commit("SET_USUARIOS_LOADING", true);
      return axiosInstance
        .get("admin/usuarios", { params: params })
        .then((e) => {
          commit("SET_USUARIOS_DATA", e.data.data);
          commit("SET_USUARIOS_LINKS", e.data.links);
          commit("SET_USUARIOS_META", e.data.meta);
          commit("SET_USUARIOS_LOADING", false);
          commit("SET_USUARIOS_LOADED", true);
          commit("SET_USUARIOS_ERRORS", {});
          return e;
        });
    },

    delete_usuario({ commit, state }, usuario_id) {
      commit("SET_USUARIOS_LOADING", true);
      return axiosInstance.delete(`admin/usuarios/${usuario_id}`).then(() => {
        const temp_data = state.usuarios.data.filter(
          (usuario) => usuario.id !== usuario_id,
        );
        commit("SET_USUARIOS_DATA", temp_data);
        commit("SET_USUARIOS_LOADING", false);
        commit("SET_USUARIOS_LOADED", true);
        commit("SET_USUARIOS_ERRORS", {});
      });
    },

    add_usuario({ commit }, payload) {
      commit("SET_USUARIOS_LOADING", true);
      return axiosInstance.post(`admin/usuarios`, payload).then(() => {
        commit("SET_USUARIOS_LOADING", false);
      });
    },

    update_usuario({ commit }, payload) {
      commit("SET_USUARIOS_LOADING", true);
      return axiosInstance
        .put(`admin/usuarios/${payload.id}`, payload)
        .then(() => {
          commit("SET_USUARIOS_LOADING", false);
        });
    },

    USUARIOS_GET_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_USUARIOS_ERRORS", error);
      commit("SET_USUARIOS_LOADING", false);
      commit("SET_USUARIOS_LOADED", false);
    },
  },

  getters: {
    usuariosData: (state) => state.usuarios,
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('gmap-autocomplete',{staticClass:"mb-2",attrs:{"set-fields-to":['formatted_address', 'geometry'],"component-restrictions":{ country: 've' },"slot-ref-name":"google_maps_input"},on:{"place_changed":_vm.place_changed}},[_c('b-input',{ref:"google_maps_input",attrs:{"value":_vm.address,"type":"text"}})],1)],1),_c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":15,"map-type-id":"terrain","options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        } ],
    }},on:{"click":_vm.markerNewPosition}},[_c('GmapMarker',{key:_vm.marker.position.lat + _vm.marker.position.lng,attrs:{"position":_vm.marker.position,"clickable":true,"draggable":true},on:{"click":_vm.marker_click,"dragend":_vm.markerNewPosition}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { axiosInstance } from "@/plugins/axios";
export default {
  namespaced: true,

  state: () => ({
    files: {
      isLoading: false,
      isLoaded: false,
      data: [],
      error: {},
    },
  }),

  mutations: {
    SET_FILES_DATA(state, files_data) {
      state.files.data = files_data;
    },

    ADD_FILE_DATA(state, file_data) {
      state.files.data.push(file_data);
    },

    SET_FILES_ERRORS(state, files_error) {
      state.files.error = files_error;
    },

    SET_FILES_LOADING(state, files_loading) {
      state.files.isLoading = files_loading;
    },

    SET_FILES_LOADED(state, files_loaded) {
      state.files.isLoaded = files_loaded;
    },

    CLEAR_FILES(state) {
      state.files.isLoading = false;
      state.files.isLoaded = false;
      state.files.data = [];
      state.files.error = {};
    },
  },

  actions: {
    get_files({ commit }) {
      commit("SET_FILES_LOADING", true);
      return axiosInstance.get("files").then((e) => {
        commit("SET_FILES_DATA", e.data.data);
        commit("SET_FILES_LOADING", false);
        commit("SET_FILES_LOADED", true);
        commit("SET_FILES_ERRORS", {});
        return e;
      });
    },

    delete_file({ commit, state }, file_id) {
      commit("SET_FILES_LOADING", true);
      return axiosInstance.delete(`files/${file_id}`).then(() => {
        const temp_data = state.files.data.filter(
          (file) => file.id !== file_id,
        );
        commit("SET_FILES_DATA", temp_data);
        commit("SET_FILES_LOADING", false);
        commit("SET_FILES_LOADED", true);
        commit("SET_FILES_ERRORS", {});
      });
    },

    update_file({ commit }, payload) {
      commit("SET_FILES_LOADING", true);
      return axiosInstance.put(`files/${payload.id}`, payload).then(() => {
        commit("SET_FILES_LOADING", false);
      });
    },

    FILES_GET_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_FILES_ERRORS", error);
      commit("SET_FILES_LOADING", false);
      commit("SET_FILES_LOADED", false);
    },
  },

  getters: {
    filesData: (state) => state.files,
  },
};

import dayjs from "dayjs";
import Vue from "vue";
import { status_suscripciones_map } from "./variables";

Vue.filter("toUpperCase", function (value) {
  return value.toUpperCase();
});

Vue.filter("jsonFormat", function (value) {
  return JSON.stringify(value, null, 2);
});

Vue.filter("formatDate", function (value) {
  if (!value) {
    return "--";
  }
  return dayjs(value).format("DD/MM/YYYY hh:mm A");
});

Vue.filter("formatDate24", function (value) {
  if (!value) {
    return "--";
  }
  return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
});

Vue.filter("formatDate_date", function (value) {
  return dayjs(value).format("DD/MM/YYYY");
});

Vue.filter("formatDate_time", function (value) {
  return dayjs(value).format(" hh:mm A");
});

Vue.filter("toDecimal", function (value) {
  if (typeof value !== "number") {
    if (typeof value == "string") {
      try {
        var formatterString = new Intl.NumberFormat("es-VE", {
          minimumFractionDigits: 0,
        });
        return formatterString.format(value);
      } catch (error) {
        return value;
      }
    } else {
      return value;
    }
  }
  var formatter = new Intl.NumberFormat("es-VE", {
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    if (typeof value == "string") {
      try {
        var formatterString = new Intl.NumberFormat("es-VE", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
        });
        return formatterString.format(value);
      } catch (error) {
        return value;
      }
    } else {
      return value;
    }
  }
  var formatter = new Intl.NumberFormat("es-VE", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter("intervalToSpanish", function (value) {
  let intervals_object = {
    month: "Mensual",
    day: "diario",
    year: "Anual",
    week: "Semanal",
  };
  return intervals_object[value];
});

Vue.filter("intervalPlanes", function (value, number = 1) {
  let intervals_object = {
    month: number < 2 ? number + " Mes" : number + " Meses",
    day: number < 2 ? number + " Dia" : number + " Días",
    year: number < 2 ? number + " Año" : number + " Años",
    week: number < 2 ? number + " Semana" : number + " Semanas",
  };
  return intervals_object[value];
});

Vue.filter("statusMap", function (value) {
  return status_suscripciones_map[value].toUpperCase();
});

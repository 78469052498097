<template>
  <div class="is-relative">
    <b-field class="field py-1" label="Nombre completo">
      <b-input v-model="parte.nombre" @change.native="updateParte"></b-input>
    </b-field>

    <b-field label="Documento (obligatorio)">
      <b-select v-model="parte.documento_tipo" @change.native="updateParte">
        <option value="V">V</option>
        <option value="E">E</option>
        <option value="J">J</option>
      </b-select>
      <b-input
        v-model="parte.documento_numero"
        placeholder="Numero de documento"
        @change.native="updateParte"
      ></b-input>
    </b-field>

    <div style="width: max-content">
      <label class="has-text-weight-bold my-2"
        >Imagen del documento (obligatorio)</label
      >
      <div>
        <b-button
          type="is-info"
          class="ml-2"
          icon-left="search-plus"
          :disabled="!url_imagen && !parte.imagen"
          @click="documentoModalActive = true"
        >
          Ampliar
        </b-button>
      </div>
      <div
        class="panel-block is-flex is-flex-direction-column is-relative"
        style="width: max-content"
      >
        <div
          class="uploadPicture has-text-info"
          @click="$refs.imagen_input.click()"
        >
          <b-icon icon="camera" size="is-large"></b-icon>
          <span>Subir imagen</span>
        </div>
        <figure
          class="image is-4by3 is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          style="width: 300px"
        >
          <img :src="documento_imagen" alt="user-avatar" class="imagen_form" />
        </figure>
        <input
          ref="imagen_input"
          type="file"
          style="display: none"
          accept=".jpg, .png, .jpeg"
          @change="imagenChange()"
        />
      </div>
      <b-progress
        v-show="uploadingDocumento"
        :value="percenProgress"
        show-value
        format="percent"
      ></b-progress>
    </div>

    <template v-if="parte.documento_tipo != 'J'">
      <!-- sexo -->
      <b-field class="field py-1" label="Sexo">
        <b-select
          v-model="parte.sexo"
          expanded
          placeholder="Seleccione un Sexo"
          @input="updateParte"
        >
          <option :value="'Hombre'">Hombre</option>
          <option :value="'Mujer'">Mujer</option>
        </b-select>
      </b-field>
      <b-field class="field py-1" label="Estado civil">
        <b-input
          v-model="parte.estado_civil"
          @change.native="updateParte"
        ></b-input>
      </b-field>
      <!-- Fecha de inicio -->
      <b-field class="field py-1" label="Fecha de nacimiento">
        <b-datepicker
          v-model="parte.fecha_nacimiento"
          placeholder="Fecha"
          :date-formatter="dateFormatter"
          locale="es-MX"
          :show-week-number="true"
          :mobile-native="false"
          :trap-focus="true"
          @input="updateParte"
        ></b-datepicker>
      </b-field>
    </template>
    <template v-else>
      <parte-juridico-form :parte-id="parte.id"></parte-juridico-form>
    </template>

    <b-field
      class="field py-1"
      :label="
        parte.documento_tipo == 'J' ? 'Dirección del responsable' : 'Dirección'
      "
    >
      <b-input
        v-model="parte.direccion"
        type="textarea"
        :rows="5"
        @change.native="updateParte"
      ></b-input>
    </b-field>

    <b-modal v-model="documentoModalActive">
      <p class="image is-4by3">
        <img :src="documento_imagen" style="object-fit: contain" />
      </p>
    </b-modal>

    <b-loading :active="isLoading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
import http_errors from "@/mixins/http_errors";
import dayjs from "dayjs";
import ParteJuridicoForm from "./ParteJuridicoForm.vue";
export default {
  name: "ContratoParteForm",
  components: { ParteJuridicoForm },
  mixins: [http_errors],
  props: {
    contratoId: {
      type: Number,
      required: true,
    },
    tipoParte: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      url_imagen: "",
      documentoModalActive: false,
      uploadingDocumento: false,
      percenProgress: 0,
      parte: {
        id: null,
        nombre: null,
        documento_tipo: null,
        documento_numero: null,
        estado_civil: null,
        fecha_nacimiento: null,
        sexo: null,
        direccion: null,
        contrato_id: this.contratoId,
        tipo_parte: this.tipoParte,
      },
    };
  },

  computed: {
    documento_imagen() {
      return this.url_imagen
        ? this.url_imagen
        : this.parte.imagen
        ? this.parte.imagen.url
        : "https://mybucketaalgi.s3.amazonaws.com/assets/default-fallback-image.png";
    },
  },

  mounted() {
    this.fetchParte();
    this.fetchEstados();
  },

  methods: {
    dateFormatter(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },

    imagenChange() {
      this.$set(
        this,
        "url_imagen",
        URL.createObjectURL(this.$refs.imagen_input.files[0]),
      );

      let formData = new FormData();

      if (this.$refs.imagen_input.files[0]) {
        formData.append("imagen", this.$refs.imagen_input.files[0]);
      }

      if (this.$route.query.token) {
        formData.append("token", this.$route.query.token);
      }

      this.percenProgress = 0;
      this.uploadingDocumento = true;

      var config = {
        onUploadProgress: (progressEvent) => {
          this.percenProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          this.$emit("progress", this.percenProgress);
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$axios
        .post(`partes/documentos/${this.parte.id}`, formData, config)
        .then(() => {
          this.uploadingDocumento = false;
        })
        .catch((error) => {
          this.uploadingDocumento = false;
          this.http_parse_error(error);
        });
    },

    fetchParte() {
      this.isLoading = true;
      this.$axios
        .get("partes", {
          params: {
            search_keys: "contrato_id,tipo_parte",
            search_values: `${this.contratoId},${this.tipoParte}`,
            token: this.$route.query.token,
          },
        })
        .then((e) => {
          this.$set(this, "parte", {
            ...this.parte,
            ...e.data.data,
            fecha_nacimiento: e.data.data.fecha_nacimiento
              ? new Date(e.data.data.fecha_nacimiento + " 12:00:00")
              : null,
          });
          if (this.parte.state_id) {
            this.fetchCiudades(this.parte.state_id);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.http_parse_error(error);
        });
    },

    fetchEstados() {
      this.estadosLoading = true;
      this.$axios
        .get("estados")
        .then((e) => {
          this.estados = e.data.data;
          this.estadosLoading = false;
        })
        .catch((error) => {
          this.estadosLoading = false;
          this.http_parse_error(error);
        });
    },

    estadosSelectCallback(estado_id) {
      this.ciudad_id = null;
      this.fetchCiudades(estado_id);
    },

    fetchCiudades(estado_id) {
      if (!estado_id) {
        return;
      }
      this.LoadingCities = true;
      this.axios
        .get(`estados/${estado_id}/ciudades`)
        .then((e) => {
          this.ciudades = e.data.data;
          this.LoadingCities = false;
        })
        .catch((error) => {
          this.LoadingCities = false;
          this.http_parse_error(error);
        });
    },

    updateParte() {
      let payload = { ...this.parte, token: this.$route.query.token };
      if (this.parte.fecha_nacimiento) {
        payload.fecha_nacimiento = dayjs(this.parte.fecha_nacimiento).format(
          "YYYY-MM-DD",
        );
      }
      this.$axios.put(`partes/${payload.id}`, payload).catch((error) => {
        this.http_parse_error(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadPicture {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 100%;
  height: 100%;
  background: $grey-lighter;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.25;
  z-index: 20;
  transition: opacity 0.25s ease-in-out;
}

.uploadPicture:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>

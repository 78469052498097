import { render, staticRenderFns } from "./ContratoForm.vue?vue&type=template&id=68792399&scoped=true&"
import script from "./ContratoForm.vue?vue&type=script&lang=js&"
export * from "./ContratoForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68792399",
  null
  
)

export default component.exports
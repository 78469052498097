<template>
  <div id="app">
    <router-view></router-view>
    <b-loading
      :active="loadingLogo"
      :is-full-page="true"
      :can-cancel="true"
      :class="{ logo: loadingLogo }"
    >
      <img
        width="300px"
        height="294"
        src="@/assets/logo.png"
        alt="RematesCol"
      />
    </b-loading>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loadingLogo: true,
    };
  },
  computed: {
    ...mapState(["showLoading"]),
  },
  mounted() {
    setTimeout(() => {
      this.loadingLogo = false;
    }, 1000);
  },
  methods: {},
};
</script>

<style lang="scss">
.loading-overlay.logo .loading-background {
  background: #fff;
}

.loading-overlay.logo .loading-background + img {
  height: auto;
  max-width: 100%;
  width: #{"min(300px, 90%)"};
  z-index: 10;
}
</style>

<template>
  <div>
    <div>
      <gmap-autocomplete
        :set-fields-to="['formatted_address', 'geometry']"
        :component-restrictions="{ country: 've' }"
        slot-ref-name="google_maps_input"
        class="mb-2"
        @place_changed="place_changed"
      >
        <b-input ref="google_maps_input" :value="address" type="text" />
      </gmap-autocomplete>
    </div>
    <GmapMap
      ref="gmap"
      :center="center"
      :zoom="15"
      map-type-id="terrain"
      style="width: 100%; height: 400px"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
      }"
      @click="markerNewPosition"
    >
      <GmapMarker
        :key="marker.position.lat + marker.position.lng"
        :position="marker.position"
        :clickable="true"
        :draggable="true"
        @click="marker_click"
        @dragend="markerNewPosition"
      >
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
import { getGoogleMapsAPI, helpers } from "gmap-vue";
const { googleMapsApiInitializer } = helpers;

export default {
  name: "GoogleMapsForm",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      address: this.value.direccion,
      center: { lat: this.value.lat, lng: this.value.lng },
      marker: {
        position: {
          lat: this.value.lat,
          lng: this.value.lng,
        },
      },
    };
  },
  computed: {
    google: getGoogleMapsAPI,
  },
  watch: {
    value: function (newVal) {
      console.log(newVal);
      this.address = newVal.direccion;
      this.center = { lat: newVal.lat, lng: newVal.lng };
      this.marker = {
        position: {
          lat: newVal.lat,
          lng: newVal.lng,
        },
      };
    },
  },
  mounted() {
    googleMapsApiInitializer(
      {
        key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        libraries: "places",
      },
      false,
    );
  },
  methods: {
    place_changed(event) {
      this.marker = {
        position: {
          lat: event.geometry.location.lat(),
          lng: event.geometry.location.lng(),
        },
      };
      this.address = event.formatted_address;
      this.center = this.marker.position;
      const new_data = {
        direccion: event.formatted_address,
        ...this.marker.position,
      };
      this.$emit("input", new_data);
    },
    marker_click(event) {
      this.center = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    },
    markerNewPosition(event) {
      let geocoder = new this.google.maps.Geocoder();
      geocoder
        .geocode({
          location: { lat: event.latLng.lat(), lng: event.latLng.lng() },
        })
        .then((response) => {
          if (response.results[0]) {
            this.address = response.results[0].formatted_address;
            this.marker = {
              position: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
              },
            };

            const new_data = {
              direccion: response.results[0].formatted_address,
              ...this.marker.position,
            };
            this.$emit("input", new_data);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>

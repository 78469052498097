import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", {
      permiso_check_userpermisos: "userPermisos",
    }),
  },
  methods: {
    can(permisos) {
      const hasPermission = this.permiso_check_userpermisos.some(
        (permission) => {
          return permisos.includes(permission);
        },
      );

      return hasPermission;
    },
  },
};

import store from "@/store";
import router from "@/router";

if (!sessionStorage.length) {
  localStorage.setItem("getSessionStorage", Date.now());
}

window.addEventListener("storage", function (event) {
  if (event.key == "getSessionStorage") {
    localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
    localStorage.removeItem("sessionStorage");
  } else if (event.key == "sessionStorage" && !sessionStorage.length) {
    var data = JSON.parse(event.newValue);
    for (const key in data) {
      sessionStorage.setItem(key, data[key]);
    }

    if (sessionStorage.length) {
      store.commit(
        "auth/SET_USER_DATA",
        JSON.parse(sessionStorage.getItem("userData")),
      );

      store.commit("auth/SET_AUTH_TOKEN", sessionStorage.getItem("auth_token"));
    }
  } else if (
    event.key == "remember_me" &&
    event.newValue == null &&
    event.oldValue == "true"
  ) {
    store.dispatch("auth/clearData");
    router.push({ name: "Login" });
  }
});

import { axiosInstance } from "@/plugins/axios";
import Vue from "vue";
export default {
  namespaced: true,

  state: () => ({
    auth_token:
      localStorage.getItem("auth_token") ||
      sessionStorage.getItem("auth_token") ||
      "",
    user: {
      isLoading: false,
      isLoaded: false,
      data: {},
      error: {},
    },
  }),

  mutations: {
    SET_USER_DATA(state, user_data) {
      Vue.set(state.user, "data", {});
      Vue.set(state.user, "data", user_data);
    },

    CHANGE_USER_DATA_KEY_VALUE(state, payload) {
      Vue.set(state.user.data, payload.key, payload.value);
    },

    SET_USER_ERRORS(state, user_error) {
      state.user.error = user_error;
    },

    SET_USER_LOADING(state, user_loading) {
      state.user.isLoading = user_loading;
    },

    SET_USER_LOADED(state, user_loaded) {
      state.user.isLoaded = user_loaded;
    },

    SET_AUTH_TOKEN(state, token) {
      state.auth_token = token;
    },

    CLEAR_USER(state) {
      state.user.isLoading = false;
      state.user.isLoaded = false;
      state.user.data = {};
      state.user.error = {};
    },
  },

  actions: {
    clearData({ commit }) {
      localStorage.removeItem("auth_token");
      sessionStorage.removeItem("auth_token");
      commit("SET_USER_DATA", {});
      commit("SET_AUTH_TOKEN", "");
    },

    logout({ commit }) {
      commit("SET_USER_LOADING", true);
      return axiosInstance.get("logout");
    },

    login({ commit, dispatch }, payload) {
      commit("SET_USER_LOADING", true);
      return axiosInstance.post("login", payload).then(async (e) => {
        if (payload.recordarme) {
          commit("SET_REMEMBER_ME", payload.recordarme);
        }
        dispatch("SET_SESSION", e.data);
        return await dispatch("currentUser");
      });
    },

    social_login({ commit }, payload) {
      commit("SET_USER_LOADING", true);
      return axiosInstance
        .get(`login/${payload.provider}/`, { params: payload.response })
        .then(() => {
          commit("SET_USER_LOADING", false);
        });
    },

    currentUser({ commit, dispatch }) {
      commit("SET_USER_LOADING", true);
      return axiosInstance.get("me").then((e) => {
        dispatch("SET_CURRENT_USER_DATA", e.data.data);
        commit("SET_USER_LOADED", true);
        commit("SET_USER_LOADING", false);
        commit("SET_USER_ERRORS", {});
        return e;
      });
    },

    SET_CURRENT_USER_DATA({ commit }, data) {
      commit("SET_USER_DATA", data);
    },

    SET_SESSION({ commit }, data) {
      localStorage.setItem("auth_token", data.access_token);
      commit("SET_AUTH_TOKEN", data.access_token);
    },

    USER_LOGIN_ERROR({ commit }, error) {
      console.log(error);
      commit("SET_USER_ERRORS", error);
      commit("SET_USER_LOADING", false);
      commit("SET_USER_LOADED", false);
    },
  },

  getters: {
    isLoggedIn: (state) => {
      return (
        state.user.data &&
        Object.keys(state.user.data).length !== 0 &&
        Object.getPrototypeOf(state.user.data) === Object.prototype
      );
    },

    isLoggedOut: (getters) => {
      return !getters.isLoggedIn;
    },

    token: (state) => state.auth_token,

    userData: (state) => state.user,
    userRoles: (state, getters) => getters.userData.data.roles,
    userPermisos: (state, getters) => getters.userData.data.permisos,
  },
};

<template>
  <transition-group
    name="flip-list"
    tag="div"
    class="columns is-multiline is-relative"
  >
    <div
      v-for="(inventario, index) in inventarios"
      :key="'inventario' + inventario.id"
      class="column is-12"
    >
      <div class="columns">
        <div class="column is-narrow-tablet pb-0">
          <div class="inventario-buttons__container">
            <b-button
              :disabled="!inventario.descripcion"
              class="m-1"
              type="is-primary"
              @click="save_inventario(inventario)"
            >
              <b-icon icon="save"></b-icon>
            </b-button>
            <b-button
              class="m-1"
              type="is-danger"
              @click="deleteInventario(inventario, index)"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </div>
        </div>
        <div class="column">
          <b-field grouped group-multiline>
            <b-input
              v-model="inventarios[index].cantidad"
              type="number"
              placeholder="Cantidad"
              :min="0"
            ></b-input>
            <b-input
              v-model="inventarios[index].descripcion"
              type="textarea"
              :rows="5"
              placeholder="Descripcion"
              expanded
            ></b-input>
            <div
              class="panel-block is-flex is-flex-direction-column is-relative mx-auto"
              style="width: max-content"
            >
              <div
                class="uploadPicture has-text-info"
                @click="clickInput(inventario)"
              >
                <b-icon icon="camera" size="is-large"></b-icon>
                <span>Subir imagen</span>
              </div>
              <figure
                class="image is-128x128 is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
              >
                <img
                  :src="
                    urls_imagenes[inventario.id]
                      ? urls_imagenes[inventario.id]
                      : inventario.imagen
                      ? inventario.imagen.thumbnail_url
                      : 'https://mybucketaalgi.s3.amazonaws.com/assets/default-fallback-image.png'
                  "
                  alt="user-avatar"
                  class="imagen_form"
                />
              </figure>
              <input
                :ref="'imagen_input' + inventario.id"
                type="file"
                style="display: none"
                accept=".jpg, .png, .jpeg"
                @change="imagenChange(inventario, index)"
              />
            </div>
          </b-field>
        </div>
      </div>
    </div>
    <div key="agregar-boton" class="column is-12 px-5 mb-5">
      <div class="has-text-right">
        <b-button type="is-primary" icon-left="plus" @click="AddInventario"
          >Item</b-button
        >
      </div>
    </div>
    <b-loading
      :key="'loadgin'"
      :active="isLoading"
      :is-full-page="false"
    ></b-loading>
  </transition-group>
</template>

<script>
import http_errors from "@/mixins/http_errors";
export default {
  name: "InventariosForm",
  mixins: [http_errors],
  props: {
    contratoId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      inventarios: [],
      urls_imagenes: {},
      isLoading: false,
    };
  },

  mounted() {
    this.fetchInventarios();
  },

  methods: {
    clickInput(inventario) {
      console.log(this.$refs["imagen_input" + inventario.id]);
      this.$refs["imagen_input" + inventario.id][0].click();
    },

    imagenChange(inventario) {
      this.$set(
        this.urls_imagenes,
        inventario.id,
        URL.createObjectURL(
          this.$refs["imagen_input" + inventario.id][0].files[0],
        ),
      );
    },

    uniqueId() {
      return Math.floor(Math.random() * Date.now()) + "-not";
    },

    save_inventario(inventario) {
      this.isLoading = true;

      let formData = new FormData();

      for (let key in inventario) {
        if (
          (inventario[key] && !Array.isArray(inventario[key])) ||
          inventario[key] === 0
        ) {
          formData.append(key, inventario[key]);
        }
      }

      if (this.$refs["imagen_input" + inventario.id][0].files[0]) {
        formData.append(
          "imagen",
          this.$refs["imagen_input" + inventario.id][0].files[0],
        );
      }

      formData.append("_method", "PUT");

      this.$axios
        .post(`inventarios/${inventario.id}`, formData)
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.http_parse_error(error);
        });
    },

    deleteInventario(inventario, index) {
      this.isLoading = true;
      this.$axios
        .delete(`inventarios/${inventario.id}`)
        .then(() => {
          this.inventarios.splice(index, 1);
          if (this.inventarios.length) {
            this.reOrder();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.http_parse_error(error);
        });
    },

    AddInventario() {
      this.isLoading = true;
      this.$axios
        .post("inventarios", {
          contrato_id: this.contratoId,
        })
        .then((e) => {
          this.inventarios.push(e.data.data);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.http_parse_error(error);
        });
    },

    fetchInventarios() {
      this.isLoading = true;
      this.$axios
        .get("inventarios", {
          params: {
            search_keys: "contrato_id",
            search_values: this.contratoId,
          },
        })
        .then((e) => {
          this.inventarios = e.data.data;
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          this.http_parse_error(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.inventario-buttons__container {
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: flex-start;

  @include tablet {
    display: flex;
    flex-flow: column;
  }
}

.flip-list-move {
  transition: transform 0.33s;
}

.uploadPicture {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 100%;
  height: 100%;
  background: $grey-lighter;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.25;
  z-index: 20;
  transition: opacity 0.25s ease-in-out;
}

.uploadPicture:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>

export default {
  data() {
    return {
      http_error_object: {},
      http_current_error: null,
    };
  },

  methods: {
    http_unexpected_error(error) {
      this.http_current_error = error;
    },
    http_parse_error(error) {
      if (error.response) {
        this.http_error_object = error.response;
        this.http_error_from_response();
      } else if (error.request) {
        this.http_error_object = error.request;
        this.http_error_from_response();
      } else {
        this.http_error_object = error;
        this.http_current_error = error[0];
      }

      return this.http_error_object;
    },
    http_error_from_response() {
      if (this.http_error_object.data.message) {
        this.http_current_error = this.http_error_object.data.message;
      } else if (this.http_error_object.status == 400) {
        this.http_current_error =
          "No se pudo realizar el pago, verifique que posee fondos suficientes. Si el problema persiste, contacte con soporte.";
      } else if (this.http_error_object.status == 401) {
        this.http_current_error = "Información invalida";
      } else if (this.http_error_object.status == 402) {
        this.http_current_error = this.http_error_object.data.data.respuesta;
      } else if (this.http_error_object.status == 404) {
        this.http_current_error =
          "<strong>Error 404</strong> - El recurso no existe";
      } else if (this.http_error_object.status == 405) {
        this.http_current_error =
          "<strong>Error 405</strong> - Método no permitido";
      } else if (this.http_error_object.status == 422) {
        this.http_current_error =
          "<strong>Error 422</strong> - La operación que intenta realizar genera un conflicto en la base de datos";
      } else if (this.http_error_object.status == 0) {
        this.http_current_error = "Error de conexión";
      } else if (this.http_error_object.status == 500) {
        this.http_current_error =
          "<strong>Error 500</strong> - Error en el servidor";
      } else {
        this.http_current_error = "Error en el servidor";
      }

      this.$buefy.dialog.alert({
        message: this.http_current_error,
        confirmText: "Cerrar",
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fas",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
  },
};

import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";
import testView from "@/views/testView.vue";

// -----------------------------------------------------------------------------------
// PUBLIC ----------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
const PublicView = () =>
  import(/* webpackChunkName: "public" */ "@/views/public/PublicView.vue");

const Home = () =>
  import(/* webpackChunkName: "public" */ "@/views/public/Home.vue");

const ContentPublic = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Content/ContentPublic.vue"
  );

const PreguntasFrecuentes = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Content/PreguntasFrecuentes.vue"
  );
const Login = () =>
  import(/* webpackChunkName: "public" */ "@/views/public/Login/Login.vue");

const PlanesList = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Planes/PlanesList.vue"
  );
const UserDashboard = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/UserDashboard/UserDashboard.vue"
  );

const GeneralView = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/UserDashboard/GeneralView.vue"
  );

const ContratosUser = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/UserDashboard/ContratosUser.vue"
  );

const UserLicence = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/UserDashboard/UserLicence.vue"
  );

const AceptarAsociado = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/UserDashboard/AceptarAsociado.vue"
  );

const PublicacionesList = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Publicaciones/PublicacionesList.vue"
  );

const PublicacionesCompartidas = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Publicaciones/PublicacionesCompartidas.vue"
  );

const PublicacionCrear = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Publicaciones/PublicacionCrear.vue"
  );

const PublicacionVer = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Publicaciones/PublicacionVer.vue"
  );

const PerfilesList = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Perfiles/PerfilesList.vue"
  );

const PerfilCrear = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Perfiles/PerfilCrear.vue"
  );

const PerfilVer = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Perfiles/PerfilVer.vue"
  );

const ContratoCrear = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Contratos/ContratoCrear.vue"
  );

const ContratoFillOwner = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Contratos/ContratoFillOwner.vue"
  );

const ContratoFirmar = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Contratos/ContratoFirmar.vue"
  );

const ContratoDetails = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Contratos/ContratoDetails.vue"
  );

const ContratoFillparte = () =>
  import(
    /* webpackChunkName: "public" */ "@/views/public/Contratos/ContratoFillparte.vue"
  );
// -----------------------------------------------------------------------------------
// ADMIN -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
const AdminLogin = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/AdminLogin/AdminLogin.vue"
  );
const AdminView = () =>
  import(/* webpackChunkName: "admin" */ "@/views/admin/AdminView.vue");
const Dashboard = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/Dashboard/Dashboard.vue"
  );
const Usuarios = () =>
  import(/* webpackChunkName: "admin" */ "@/views/admin/Usuarios/Usuarios.vue");
const RolesC = () =>
  import(/* webpackChunkName: "admin" */ "@/views/admin/Roles/Roles.vue");

const Condiciones = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/publicaciones/Condiciones/Condiciones.vue"
  );

const PublicacionesAdmin = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/publicaciones/PublicacionesAdmin.vue"
  );
const Servicios = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/publicaciones/Servicios/Servicios.vue"
  );
const Inmuebles = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/publicaciones/Inmuebles/Inmuebles.vue"
  );

const Licencias = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/Licencias/Licencias.vue"
  );
const Planes = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/Licencias/Planes/Planes.vue"
  );
const Pagos = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/Licencias/Pagos/Pagos.vue"
  );
const SendEmails = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/SendEmails/SendEmails.vue"
  );

const PreguntasAdmin = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/Content/Preguntas/Preguntas.vue"
  );

const ContentsAdmin = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/Content/Contents/Contents.vue"
  );
const Auditoria = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/Auditoria/Auditoria.vue"
  );

const FixedClausulas = () =>
  import(
    /* webpackChunkName: "admin" */ "@/views/admin/Contratos/FixedClausulas.vue"
  );
Vue.use(VueRouter);

// Rutas publicas ( paginas principales )  --------------------------

const routes_public = [
  {
    path: "/",
    component: PublicView,
    children: [
      { path: "test", name: "Test", component: testView },
      { path: "", name: "Home", component: Home },
      {
        path: "/que-es-aalgi",
        name: "QueEsAalgi",
        component: ContentPublic,
        meta: { hasContent: true },
      },
      {
        path: "/contratos-electronicos",
        name: "ContratosElectronicos",
        component: ContentPublic,
        meta: { hasContent: true },
      },
      {
        path: "/terminos-y-condiciones",
        name: "TerminosYCondiciones",
        component: ContentPublic,
        meta: { hasContent: true },
      },
      {
        path: "/politicas-de-privacidad",
        name: "PoliticasDePrivacidad",
        component: ContentPublic,
        meta: { hasContent: true },
      },
      {
        path: "/politicas-de-uso",
        name: "PoliticasDeUso",
        component: ContentPublic,
        meta: { hasContent: true },
      },
      {
        path: "/preguntas-frecuentes",
        name: "PreguntasFrecuentes",
        component: PreguntasFrecuentes,
      },
      {
        path: "/contactenos",
        name: "Contactenos",
        component: ContentPublic,
        meta: { hasContent: true },
      },
      {
        path: "/planes",
        name: "PlanesList",
        component: PlanesList,
        meta: { auth: true },
      },
      { path: "/login", name: "Login", component: Login },
      {
        path: "/dashboard/licencia/aceptar-asociacion",
        name: "AceptarAsociado",
        component: AceptarAsociado,
        meta: {
          auth: true,
        },
      },
      {
        path: "/dashboard/licencia",
        name: "UserLicence",
        component: UserLicence,
        meta: {
          auth: true,
        },
      },
      {
        path: "/dashboard",
        component: UserDashboard,
        meta: {
          auth: true,
        },
        children: [
          {
            path: "",
            name: "UserDashboard",
            component: GeneralView,
            meta: { auth: true },
          },
          {
            path: "contratos",
            name: "ContratosUser",
            component: ContratosUser,
            meta: { auth: true },
          },
        ],
      },
      {
        path: "publicaciones/search/:perfilId",
        name: "PublicacionesList",
        component: PublicacionesList,
        meta: {
          auth: true,
        },
      },
      {
        path: "publicaciones/compartidas",
        name: "PublicacionesCompartidas",
        component: PublicacionesCompartidas,
      },
      {
        path: "publicaciones/crear",
        name: "PublicacionCrear",
        component: PublicacionCrear,
      },
      {
        path: "publicaciones/editar/:publicacionEdit",
        name: "PublicacionEditar",
        component: PublicacionCrear,
        meta: {
          auth: true,
        },
      },
      {
        path: "publicaciones/ver/:publicacionId",
        name: "PublicacionVer",
        component: PublicacionVer,
      },
      {
        path: "candidatos/search/:publicacionId",
        name: "PerfilesList",
        component: PerfilesList,
        meta: {
          auth: true,
        },
      },
      {
        path: "perfiles/crear",
        name: "PerfilCrear",
        component: PerfilCrear,
      },
      {
        path: "perfiles/editar/:perfilEdit",
        name: "PerfilEditar",
        component: PerfilCrear,
        meta: {
          auth: true,
        },
      },
      {
        path: "perfiles/ver/:perfilId",
        name: "PerfilVer",
        component: PerfilVer,
        meta: {
          auth: true,
        },
      },
      {
        path: "contratos/crear",
        name: "ContratoCrear",
        component: ContratoCrear,
        meta: {
          auth: true,
        },
      },

      {
        path: "contratos/llenar/:contratoId/owner",
        name: "ContratoFillOwner",
        component: ContratoFillOwner,
      },

      {
        path: "contratos/firmar/:contratoId",
        name: "ContratoFirmar",
        component: ContratoFirmar,
      },

      {
        path: "contratos/ver-final/:contratoId",
        name: "ContratoDetails",
        component: ContratoDetails,
      },

      {
        path: "contratos/llenar/:contratoId/parte",
        name: "ContratoFillparte",
        component: ContratoFillparte,
      },
    ],
  },
];

// Rutas del panel de administración --------------------------------

const routes_admin = [
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin",
    component: AdminView,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          auth: true,
          permisos: ["ver admin panel"],
        },
      },
      {
        path: "usuarios",
        name: "Usuarios",
        component: Usuarios,
        meta: {
          auth: true,
          permisos: ["ver admin usuarios"],
        },
      },
      {
        path: "roles",
        name: "Roles",
        component: RolesC,
        meta: {
          auth: true,
          permisos: ["ver admin roles"],
        },
      },
      {
        path: "publicaciones",
        name: "PublicacionesAdmin",
        component: PublicacionesAdmin,
        meta: {
          auth: true,
          permisos: ["ver admin publicaciones"],
        },
      },
      {
        path: "condiciones",
        name: "Condiciones",
        component: Condiciones,
        meta: {
          auth: true,
          permisos: ["ver admin condiciones"],
        },
      },
      {
        path: "servicios",
        name: "Servicios",
        component: Servicios,
        meta: {
          auth: true,
          permisos: ["ver admin servicios"],
        },
      },
      {
        path: "inmuebles",
        name: "Inmuebles",
        component: Inmuebles,
        meta: {
          auth: true,
          permisos: ["ver admin inmuebles"],
        },
      },
      {
        path: "licencias",
        name: "Licencias",
        component: Licencias,
        meta: {
          auth: true,
          permisos: ["ver admin licencias"],
        },
      },
      {
        path: "planes",
        name: "PlanesAdmin",
        component: Planes,
        meta: {
          auth: true,
          permisos: ["ver admin planes"],
        },
      },
      {
        path: "pagos",
        name: "Pagos",
        component: Pagos,
        meta: {
          auth: true,
          permisos: ["ver admin pagos"],
        },
      },
      {
        path: "preguntas-frecuentes",
        name: "PreguntasAdmin",
        component: PreguntasAdmin,
        meta: {
          auth: true,
          permisos: ["ver admin content"],
        },
      },
      {
        path: "contenidos",
        name: "ContentsAdmin",
        component: ContentsAdmin,
        meta: {
          auth: true,
          permisos: ["ver admin content"],
        },
      },
      {
        path: "send-emails",
        name: "SendEmails",
        component: SendEmails,
        meta: {
          auth: true,
          permisos: ["ver admin emails"],
        },
      },
      {
        path: "auditoria",
        name: "Auditoria",
        component: Auditoria,
        meta: {
          auth: true,
          permisos: ["ver admin planes"],
        },
      },
      {
        path: "fixed-clausulas",
        name: "FixedClausulas",
        component: FixedClausulas,
        meta: {
          auth: true,
          permisos: ["ver admin fixedClausulas"],
        },
      },
    ],
  },
];

// Union de rutas publicas y de administracion ----------------------
const routes = routes_public.concat(routes_admin);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  linkExactActiveClass: "is-active",
  routes,
});

// Navigation guard con roles  --------------------------------------
router.beforeEach((to, from, next) => {
  const { auth, permisos } = to.meta;
  const user_permisos = store.getters && store.getters["auth/userPermisos"];

  const expresion = /^\/admin/i;
  if (auth) {
    if (!user_permisos) {
      if (expresion.test(to.path) && to.name != "AdminLogin") {
        return next({ name: "AdminLogin", query: { returnUrl: to.fullPath } });
      } else {
        console.log(to);
        return next({ name: "Login", query: { returnUrl: to.fullPath } });
      }
    }
    if (permisos) {
      const permisosRequeridos = permisos;
      const hasRole = user_permisos.some((role) => {
        return permisosRequeridos.includes(role);
      });

      if (!hasRole) {
        return next({ name: "Home" });
      }
    }
  }

  next();
});

export default router;

<template>
  <div>
    <contrato-form :contrato-id="1"></contrato-form>
    <inventario-form :contrato-id="1"></inventario-form>
    <contrato-parte-form :contrato-id="1" :tipo-parte="0"></contrato-parte-form>
    <!-- <contrato-inquilinos-form :contrato-id="1"></contrato-inquilinos-form>
    <clausulas-form :contrato-id="1"></clausulas-form>
    <contrato-registro-form :contrato-id="1"></contrato-registro-form> -->
  </div>
</template>

<script>
// import ClausulasForm from "@/components/ContratoForms/ClausulasForm.vue";
// import ContratoRegistroForm from "@/components/ContratoForms/ContratoRegistroForm.vue";
// import ContratoInquilinosForm from "@/components/ContratoForms/ContratoInquilinosForm.vue";
import ContratoParteForm from "@/components/ContratoForms/ContratoParteForm.vue";
import InventarioForm from "@/components/ContratoForms/InventarioForm.vue";
import ContratoForm from "@/components/ContratoForms/ContratoForm.vue";
export default {
  components: {
    // ClausulasForm,
    // ContratoRegistroForm,
    // ContratoInquilinosForm,
    ContratoParteForm,
    InventarioForm,
    ContratoForm,
  },
};
</script>

<style></style>

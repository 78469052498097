import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth.store";
import account from "./modules/account.store";

import usuarios from "./modules/admin/usuarios.store";
import roles from "./modules/admin/roles.store";
import files from "./modules/admin/files.store";
import models from "./modules/admin/models.store";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    auth,
    account,
    usuarios,
    roles,
    files,
    models,
  },
  state: {
    showLoading: true,
  },
  mutations: {
    SET_SHOW_LOADING(state, flag) {
      state.showLoading = flag;
    },
  },
});

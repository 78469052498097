<template>
  <div>
    <ckeditor
      ref="ckeditor4"
      :value="value"
      :editor-url="editor"
      :config="editorConfig"
      class="content"
      @input="$emit('input', $event)"
      @ready="onReady"
    ></ckeditor>
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue";

export default {
  name: "RichEditor",
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: `${process.env.VUE_APP_BACKEND_URL}/js/ckeditor/ckeditor.js`,
      editorConfig: {
        filebrowserUploadUrl: `${process.env.VUE_APP_BACKEND_URL}/api/admin/quill-images`,
        fileTools_requestHeaders: {
          Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          Accept: "application/json",
        },
        toolbar: [
          {
            name: "document",
            items: [
              "Source",
              "-",
              "NewPage",
              "Preview",
              "Print",
              "-",
              "Templates",
            ],
          },
          {
            name: "clipboard",
            items: [
              "Cut",
              "Copy",
              "Paste",
              "PasteText",
              "PasteFromWord",
              "-",
              "Undo",
              "Redo",
            ],
          },
          {
            name: "editing",
            items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
          },
          "/",
          {
            name: "basicstyles",
            items: [
              "Bold",
              "Italic",
              "Underline",
              "Strike",
              "Subscript",
              "Superscript",
              "-",
              "CopyFormatting",
              "RemoveFormat",
            ],
          },
          {
            name: "paragraph",
            items: [
              "NumberedList",
              "BulletedList",
              "-",
              "Outdent",
              "Indent",
              "-",
              "Blockquote",
              "CreateDiv",
              "-",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
              "-",
              "BidiLtr",
              "BidiRtl",
              "Language",
            ],
          },
          { name: "links", items: ["Link", "Unlink", "Anchor"] },
          {
            name: "insert",
            items: [
              "Image",
              "Table",
              "HorizontalRule",
              "Smiley",
              "SpecialChar",
              "PageBreak",
              "Iframe",
            ],
          },
          "/",
          { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
          { name: "colors", items: ["TextColor", "BGColor"] },
          { name: "tools", items: ["Maximize", "ShowBlocks"] },
          { name: "others", items: ["SimpleLink"] },
          { name: "about", items: ["About"] },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    onReady() {
      let boton_preview = document.querySelector("[title='Vista Previa']");

      if (boton_preview) {
        boton_preview.onclick = null;
        boton_preview.addEventListener("click", () => {
          let mywindow_preview = window.open("", "_blank");
          mywindow_preview.document.body.innerHTML = this.value;
        });
      }

      let boton_print = document.querySelector(".cke_button__print");

      if (boton_print) {
        boton_print.onclick = null;
        boton_print.addEventListener("click", () => {
          let mywindow_print = window.open("", "_blank");
          mywindow_print.document.body.innerHTML = this.value;
          mywindow_print.focus();
          mywindow_print.print();
          mywindow_print.close();
        });
      }
    },
  },
};
</script>
